<template>
  <div class="d-flex justify-center flex-column agreement__page">
    <div class="d-flex mt-12">
      <v-icon color="purple" x-large>mdi-note-text</v-icon>
    </div>

    <div class="d-flex">
      <h1 class="purple--text">Agreement</h1>
    </div>

    <v-divider class="mt-6 mb-6"></v-divider>

    <div class="d-flex"><h2 class="">Table of Contents</h2></div>
    <div class="d-flex"><h3 class="">I. Offer</h3></div>
    <div class="d-flex"><h3 class="">II. Policy</h3></div>
    <div class="d-flex"><h3 class="">III. Release</h3></div>

    <v-divider class="mt-6 mb-6"></v-divider>

    <div class="d-flex">
      <h2 class="">Offer</h2>
    </div>

    <h4 v-if="content" class="font-weight-regular mt-3" v-html="content" />

    <br />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  }
};
</script>
